.account-management-container {
  background: #f8f9fa;
}

.account-menu {
  background: #ff6b6b;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.account-menu-item {
  color: #fff;
  font-weight: 500;
  transition: all 0.3s ease;
}

.account-menu-item.Mui-selected {
  background: #fff !important;
  color: #ff6b6b !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.account-menu-item:hover {
  background: rgba(255, 255, 255, 0.2) !important;
}

.account-content {
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.account-content:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.account-avatar {
  border: 2px solid #ff6b6b;
  transition: transform 0.3s ease;
}

.account-avatar:hover {
  transform: scale(1.05);
}

.account-upload-btn {
  background: #ff6b6b;
  color: #fff;
  border-radius: 8px;
  padding: 8px 16px;
  transition: all 0.3s ease;
}

.account-upload-btn:hover {
  background: #ff4d4d;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.account-info {
  font-size: 1rem;
  color: #2d3436;
  margin-bottom: 10px;
}

.account-info strong {
  color: #ff6b6b;
}

.account-divider {
  background: #ff6b6b;
  height: 2px;
  margin: 20px 0;
}

.account-update-btn {
  background: #ff6b6b;
  color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.account-update-btn:hover {
  background: #ff4d4d;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Kiểu dáng cho hàng chứa "Nhóm khách hàng" */
.vip-row {
  display: flex;
  align-items: center;
  white-space: nowrap; /* Ngăn xuống dòng */
}

/* Kiểu dáng cho biểu tượng VIP */
.vip-icon {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  transition: transform 0.3s ease;
}

.vip-icon:hover {
  transform: scale(1.2);
}

/* Kiểu dáng cho cấp độ VIP (VIP Diamond, VIP Gold, v.v.) */
.vip-level {
  display: inline; /* Đảm bảo inline */
}

/* Tooltip khi hover vào Nhóm khách hàng */
.account-vip-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 4px; /* Khoảng cách giữa "VIP [Cấp độ]" và biểu tượng */
}

.account-vip-wrapper:hover .vip-tooltip {
  visibility: visible;
  opacity: 1;
}

.vip-tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  white-space: nowrap;
  transition: opacity 0.2s ease;
  z-index: 10;
}

.vip-tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

/* Responsive */
@media (max-width: 767px) {
  .account-menu {
    margin-bottom: 20px;
  }

  .account-info {
    font-size: 0.9rem;
  }

  .vip-tooltip {
    font-size: 0.8rem;
    padding: 4px 8px;
  }

  .vip-icon {
    width: 20px !important;
    height: 20px !important;
    margin-left: 4px !important;
  }

  .account-vip-wrapper {
    gap: 2px; /* Giảm khoảng cách trên mobile */
  }

  /* Cho phép xuống dòng trên mobile nếu nội dung quá dài */
  .vip-row {
    white-space: normal;
  }
}